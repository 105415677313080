import React from "react";

import { useAtom } from "jotai";
import { Navigate } from "react-router-dom";
import { redirectAtom, userAtom } from "../../atoms";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const [user] = useAtom(userAtom);
  const [, setRedirect] = useAtom(redirectAtom);
  setRedirect(`${window.location.pathname}${window.location.search}`);
  if (user == null) {
    return <Navigate to="/login" />;
  }
  if (user.role === "UnverifiedUser") {
    return <Navigate to="/verify" />;
  }
  
  return children;
};

export default ProtectedRoute;
