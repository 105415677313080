import {
  Button,
  Flex,
  Text,
  Image,
  useColorModeValue,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Grid,
  GridItem,
  Avatar
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getContractsByStatus, getContractsCountByStatus } from '../../api';
import { dashboardViewAtom, userAtom } from '../../atoms';
import { capitalize } from '../../utils/capitalize';
import { Dropdown } from '../icons/Dropdown';
import { dashboardTheme } from 'src/theme/theme';

export interface CompletedContractsCondensedProps extends BoxProps {
  setReloadNegotiations: any;
}

export const CompletedContractsCondensed: React.FC<
  CompletedContractsCondensedProps
> = ({ setReloadNegotiations, ...props }) => {
  const bg = useColorModeValue('brand.secondary1', 'brand.secondary2');
  const color = useColorModeValue('brand.secondary2', 'brand.secondary1');
  const highlightColor = useColorModeValue('#6B30BA', 'brand.primary');
  const [user] = useAtom(userAtom);
  const [, setView] = useAtom(dashboardViewAtom);
  const [contracts, setContracts] = useState<any>([]);
  const [count, setCount] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    const getContracts = async () => {
      const { data } = await getContractsByStatus(3, 0, 'both-signed');
      setContracts(data);
    };

    getContracts();
  }, [setContracts]);

  useEffect(() => {
    const getCount = async () => {
      const { data } = await getContractsCountByStatus('both-signed');
      setCount(data.count);
    };

    getCount();
  }, [setCount]);

  const handleOnClick = async (contract: any) => {
    navigate(`/negotiation/${contract.negotiation._id}`);
    //navigate(`/contract/${contract._id}`);
  };

  return (
    <Box {...props}>
      <Accordion allowToggle={false} defaultIndex={0} variant="summary">
        <AccordionItem>
          {({ isExpanded }) => (
            <>
              <AccordionButton
                background={dashboardTheme.sentOfferHeadingBackground}
              >
                <Flex gap="15.3px" w="100%" alignItems="center">
                  <Text
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="12px"
                    color={dashboardTheme.sentOfferHeadingColor}
                  >
                    Completed contracts ({count})
                  </Text>
                  <Button
                    as="span"
                    color={dashboardTheme.viewAllColor}
                    variant="transparent"
                    h="24px"
                    w="88.79px"
                    borderColor={dashboardTheme.viewAllBackgronudColor}
                    borderWidth={1}
                    borderStyle={'solid'}
                    onClick={(e) => {
                      e.preventDefault();
                      setView('CompletedContracts');
                    }}
                  >
                    View all
                  </Button>
                </Flex>
                {/* <Dropdown
                  color={highlightColor}
                  h="4px"
                  w="12px"
                  sx={
                    isExpanded
                      ? {
                          transform: "rotate(180deg)",
                        }
                      : {}
                  }
                /> */}
              </AccordionButton>

              <AccordionPanel>
                {contracts.map((contract: any) => {
                  const isInitiator = contract.initiator.email === user?.email;

                  let otherParty = isInitiator
                    ? contract.receiver
                    : contract.initiator;

                  if (otherParty === null || otherParty === undefined) {
                    otherParty = {
                      firstName: isInitiator
                        ? contract.receiverEmail
                        : contract.initiatorEmail,
                      lastName: '',
                      profileImageUrl: ''
                    };
                  }

                  return (
                    <Grid
                      templateColumns={{
                        base: 'repeat(2, 1fr)',
                        lg: 'repeat(3, 1fr)'
                      }}
                      background={dashboardTheme.completedContractsBackground}
                      borderColor={'black'}
                      borderWidth={1}
                      borderStyle={'solid'}
                      mb="9px"
                      key={contract._id}
                      p="16px 18px"
                      borderRadius="4px"
                    >
                      <GridItem>
                        <Flex flexDirection="column" gap="8.75px">
                          <Text color={color}>
                            {contract.contractType.subjectType}: "
                            {capitalize(contract.negotiation.subject)}"
                          </Text>
                          <Flex flexDirection="column" gap="8px">
                            <Text
                              color={color}
                              fontSize="12px"
                              fontWeight="700"
                              lineHeight="14px"
                            >
                              {new Date(
                                contract.negotiation.createdAt
                              ).toDateString()}
                            </Text>
                            <Text
                              color={
                                dashboardTheme.completedContractContractTypeTextColor
                              }
                              fontSize="10px"
                              fontWeight="700"
                              lineHeight="12px"
                            >
                              {contract.contractType.name}
                            </Text>
                          </Flex>
                        </Flex>
                      </GridItem>

                      <GridItem>
                        <Flex
                          gap="8px"
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <Avatar src={otherParty.profileImageUrl} size="sm" />
                          <Flex flexDirection="column">
                            <Text
                              fontSize="12px"
                              fontWeight="400"
                              lineHeight="16px"
                              color={color}
                            >
                              {otherParty.firstName} {otherParty.lastName}
                            </Text>
                            <Text
                              fontSize="10px"
                              fontWeight="700"
                              lineHeight="12px"
                              color={color}
                            >
                              {isInitiator
                                ? capitalize(
                                    contract.negotiation.receiverRoleLabel ||
                                      contract.negotiation.receiverRole
                                  )
                                : capitalize(
                                    contract.negotiation.initiatorRoleLabel ||
                                      contract.negotiation.initiatorRole
                                  )}
                            </Text>
                          </Flex>
                        </Flex>
                      </GridItem>

                      <GridItem
                        colSpan={{ base: 2, lg: 1 }}
                        mt={{ base: '24px', lg: '0' }}
                      >
                        <Flex justifyContent="flex-end">
                          <Button
                            variant="transparent"
                            h={{ base: '40px', lg: '24px' }}
                            width={{ base: '100%', lg: '103px' }}
                            borderRadius="6px"
                            padding={{ base: '', lg: '0px, 8px, 0px, 8px' }}
                            onClick={() => handleOnClick(contract)}
                            color={
                              dashboardTheme.completedContractContractTypeTextColor
                            }
                          >
                            Review
                          </Button>
                        </Flex>
                      </GridItem>
                    </Grid>
                  );
                })}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
