import {
  Button,
  Flex,
  FlexProps,
  Text,
  Image,
  useColorModeValue,
  Grid,
  GridItem,
  Avatar
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getContractsByStatusNoLimit } from '../../api';
import { userAtom } from '../../atoms';
import { capitalize } from '../../utils/capitalize';
import { ListHeader } from './ListHeader';
import { dashboardTheme } from 'src/theme/theme';

export interface CompletedContractsProps extends FlexProps {
  setReloadNegotiations: any
}

export const CompletedContracts: React.FC<CompletedContractsProps> = ({
  setReloadNegotiations,
  ...props
}) => {
  const bg = useColorModeValue('brand.secondary1', 'brand.secondary2');
  const color = useColorModeValue('brand.secondary2', 'brand.secondary1');
  const highlightColor = useColorModeValue('#6B30BA', 'brand.primary');

  const [user] = useAtom(userAtom);
  const [contracts, setContracts] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getContracts = async () => {
      const { data } = await getContractsByStatusNoLimit('both-signed');
      setContracts(data);
    };

    getContracts();
  }, [setContracts]);

  const handleOnClick = async (contract: any) => {
    navigate(`/negotiation/${contract.negotiation._id}`);
  };

  return (
    <Flex flexDir="column" {...props}>
      <ListHeader
        title="Completed Contracts"
        mb="16px"
        color={dashboardTheme.primaryTextColor}
        setReloadNegotiations = {setReloadNegotiations}
      />
      {contracts.map((contract: any) => {
        const isInitiator = contract.initiator.email === user?.email;
        let otherParty = isInitiator ? contract.receiver : contract.initiator;

        if (otherParty === null || otherParty === undefined) {
          otherParty = {
            firstName: isInitiator
              ? contract.receiverEmail
              : contract.initiatorEmail,
            lastName: '',
            profileImageUrl: ''
          };
        }

        return (
          <Grid
            background={dashboardTheme.completedContractsBackground}
            p="16px 18px"
            borderRadius="4px"
            alignItems="center"
            mb="9px"
            key={contract._id}
            templateColumns={{
              base: 'repeat(2, 1fr)',
              lg: 'repeat(4, 1fr)'
            }}
          >
            <GridItem>
              <Flex flexDirection="column" gap="8.75px">
                <Text color={color}>
                  {contract.contractType.subjectType}: "
                  {capitalize(contract.negotiation.subject)}"
                </Text>
                <Text
                  color={dashboardTheme.completedContractContractTypeTextColor}
                  fontSize="10px"
                  fontWeight="700"
                  lineHeight="12px"
                >
                  {contract.contractType.name}
                </Text>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex gap="8px" alignItems="center">
                <Avatar src={otherParty.profileImageUrl} size="sm" />
                <Flex flexDirection="column">
                  <Text
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="16px"
                    color={color}
                  >
                    {otherParty.firstName} {otherParty.lastName}
                  </Text>
                  <Text
                    fontSize="10px"
                    fontWeight="700"
                    lineHeight="12px"
                    color={color}
                  >
                    {isInitiator
                      ? capitalize(
                          contract.negotiation.receiverRoleLabel ||
                            contract.negotiation.receiverRole
                        )
                      : capitalize(
                          contract.negotiation.initiatorRoleLabel ||
                            contract.negotiation.initiatorRole
                        )}
                  </Text>
                </Flex>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex flexDir="column">
                <Text
                  color={color}
                  fontSize="12px"
                  fontWeight="700"
                  lineHeight="14px"
                >
                  Sent on:{' '}
                  {new Date(contract.negotiation.createdAt).toDateString()}
                </Text>
                <Text
                  color={color}
                  fontSize="12px"
                  fontWeight="700"
                  lineHeight="14px"
                >
                  at:{' '}
                  {new Date(
                    contract.negotiation.createdAt
                  ).toLocaleTimeString()}
                </Text>
              </Flex>
            </GridItem>
            <GridItem>
              <Button
                variant="transparent"
                h="32px"
                width="103px"
                borderRadius="6px"
                padding="0px, 8px, 0px, 8px"
                onClick={() => handleOnClick(contract)}
                color={color}
              >
                Review
              </Button>
            </GridItem>
          </Grid>
        );
      })}
    </Flex>
  );
};
