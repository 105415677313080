import React, { useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Button,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  ExpandedIndex,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Stack,
  Switch,
  Text,
  useColorModeValue,
  Avatar
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import _cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import moment from 'moment';
import { parseQuestionLabel } from '../../utils/parseQuestionLabel';

import { userAtom } from 'src/atoms';
import {
  Answer,
  ContractType,
  Negotiation,
  NegotiationHistory,
  NegotiationPanelData,
  NegotiationStep,
  NegotiationTerm,
  RoleResponse
} from 'src/interfaces';
import { mergeAndSanitizeAnswers } from 'src/utils/mergeAndSanitizeAnswers';
import { parseAnswers } from 'src/utils/parseAnswers';
import { parseConditions } from 'src/utils/parseConditions';
import { ChartaProse } from 'src/components/core/ChartaProse';
import { ChartaText } from 'src/components/core/ChartaText';
import { ChartaField } from 'src/components/form/ChartaField';
import { Check } from 'src/components/icons/Check';
import { Dropdown } from 'src/components/icons/Dropdown';
import { Info } from 'src/components/icons/Info';
import { Notification } from 'src/components/icons/Notification';
import cloneDeep from 'lodash/cloneDeep';
import { addCommasToNumber } from '../../utils/addCommasToNumber';
import { negotiationPanelTheme } from 'src/theme/theme';
import { useNavigate } from 'react-router-dom';

export interface NegotiationPanelProps extends BoxProps {
  negotiation: Negotiation;
  isFirstCounter: boolean;
  onSubmitAnswers: (answers: Answer[]) => void;
  onViewContract: () => void;
  onOpenPayment: () => void;
}

interface AccordionState {
  index: number;
  steps: NegotiationStep[];
}

export const NegotiationPanel: React.FC<NegotiationPanelProps> = ({
  negotiation,
  isFirstCounter,
  onSubmitAnswers,
  onViewContract,
  onOpenPayment,
  ...props
}) => {
  const navigate = useNavigate();
  const bg = negotiationPanelTheme.boxBackground;
  const termBackground = '#1B1B1B';
  const headerColor = negotiationPanelTheme.headingTextColor;
  const highlightColor = negotiationPanelTheme.highlightColor;
  const textColor = 'white';
  const notificationColor = useColorModeValue('#DE970D', '#F6C547');
  const successColor = useColorModeValue('#159700', '#7BFF66');
  const tableBorderColor = useColorModeValue(
    '#676767',
    'rgba(255, 255, 255, 0.7)'
  );
  const tableTextColor = useColorModeValue(
    '#676767',
    'rgba(255, 255, 255, 0.7)'
  );
  const panelDividerColor = useColorModeValue(
    'rgba(30, 32, 41, 0.8)',
    'rgba(255, 255, 255, 0.1)'
  );
  const offerColor = useColorModeValue('#DE970D', '#F6C547');
  const isContractSummary =
    negotiation.status !== 'active-contract' &&
    negotiation.status !== 'created';
  const [user] = useAtom(userAtom);
  const [newAnswers, setNewAnswers] = useState<Answer[]>([]);
  const [viewStatuses, setViewStatuses] = useState<Record<string, boolean>>({});
  const [historyStatuses, setHistoryStatuses] = useState<
    Record<string, boolean>
  >({});
  const [accordionState, setAccordionState] = useState<AccordionState>({
    index: -1,
    steps: []
  });
  const [tooltipState, setTooltipState] = useState<{
    header: string;
    content: string;
    isOpen: boolean;
  }>({
    header: '',
    content: '',
    isOpen: false
  });
  const [panelData, setPanelData] = useState<NegotiationPanelData>({
    subject: '',
    subjectName: '',
    steps: [],
    referenceAnswers: []
  });

  const termsNegotiated = panelData.steps
    .map((step) => step.counteredCount)
    .reduce((acc, curr) => acc + curr, 0);
  const termsRemaining =
    panelData.steps
      .map((step) => step.negotiatingCount)
      .reduce((acc, curr) => acc + curr, 0) - termsNegotiated;

  const {
    register,
    getValues,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors }
  } = useForm();
  watch();

  const {
    initiator,
    receiver,
    latestInitiatorResponses,
    latestReceiverResponses,
    receiverResponses,
    initiatorResponses,
    createdAt,
    currentResponder,
    contractType
  } = negotiation;
  const {
    name: contractTypeName,
    form: { steps },
    conditions,
    validations,
    nonNegotiable,
    subjectType,
    subjectFieldName
  } = contractType as ContractType;
  const isUserInitiator = user!.email === initiator.email;
  let isUserResponding =
    (isUserInitiator && currentResponder === 'initiator') ||
    (!isUserInitiator && currentResponder === 'receiver');

  const getUserLatestAnswers = (): Answer[] => {
    return isUserInitiator
      ? latestInitiatorResponses.answers
      : latestReceiverResponses?.answers || [];
  };

  const getOtherPartyLatestAnswers = (): Answer[] => {
    return isUserInitiator
      ? latestReceiverResponses?.answers || []
      : latestInitiatorResponses.answers;
  };

  const getReferenceAnswers = (
    baseAnswers: Answer[],
    mainAnswers: Answer[]
  ): Answer[] => {
    const base = [...baseAnswers];
    const main = [...mainAnswers];

    for (let i = 0; i < main.length; i++) {
      const j = base.findIndex(
        (answer: Answer) => answer.fieldId === main[i].fieldId
      );
      if (j > -1) {
        base[j] = main[i];
      } else {
        base.push({ ...main[i] });
      }
    }
    return base;
  };

  const formatHistory = (
    response: RoleResponse,
    party: any,
    id: string
  ): NegotiationHistory | null => {
    if (contractType != null && (contractType as ContractType)?.oneSided) {
      return null;
    }
    const answer = response.answers.find(
      ({ questionId }: any) => questionId === id
    );

    if (answer) {
      return {
        name: `${party.firstName} ${party.lastName}`,
        role: party.role,
        imageUrl: party.profileImageUrl,
        value: answer.value,
        date: new Date(response.date).toLocaleDateString()
      };
    } else {
      return null;
    }
  };

  const getNegotiationTermHistory = (
    questionId: string
  ): NegotiationHistory[] => {
    let history: NegotiationHistory[] = [];

    const newResponse = {
      date: new Date().toDateString(),
      answers: [...newAnswers]
    };

    const newInitiatorResponses = [...initiatorResponses];
    const newReceiverResponses = [...receiverResponses];

    if (isUserInitiator) {
      newInitiatorResponses.push(newResponse);
    } else {
      newReceiverResponses.push(newResponse);
    }

    let initiatorDone = false;
    let receiverDone = false;
    let count = 0;

    while (!initiatorDone || !receiverDone) {
      if (count > newInitiatorResponses.length - 1) {
        initiatorDone = true;
      } else {
        const initiatorResponse = newInitiatorResponses[count];
        const initiatorAnswer = formatHistory(
          initiatorResponse,
          initiator,
          questionId
        );
        if (initiatorAnswer) {
          history.push(initiatorAnswer);
        }
      }

      if (count > newReceiverResponses.length - 1) {
        receiverDone = true;
      } else {
        const receiverResponse = newReceiverResponses[count];
        const receiverAnswer = formatHistory(
          receiverResponse,
          receiver,
          questionId
        );
        if (receiverAnswer) {
          history.push(receiverAnswer);
        }
      }
      count = count + 1;
    }
    return history;
  };

  const checkIfDummyField = (answer: any, contractType: any) => {
    if (
      contractType?.listOfFieldsToPlaceDummyDataIn != null &&
      Array.isArray(contractType?.listOfFieldsToPlaceDummyDataIn)
    ) {
      for (const dummyField of contractType.listOfFieldsToPlaceDummyDataIn) {
        if (answer.fieldId === dummyField.fieldId) {
          return true;
        }
      }
    }
    return false;
  };
  const addIsDummyToAnswerArray = (answers: any) => {
    return _cloneDeep(answers).map((answer: any) => {
      // return answer;
      const isDummyField = checkIfDummyField(answer, contractType);
      if (!isDummyField) {
        return answer;
      }
      return {
        ...answer,
        isDummyField
      };
    });
  };

  useEffect(() => {
    //Get user latest answers
    const answersToValidate = addIsDummyToAnswerArray(newAnswers);
    const latestAnswers = getUserLatestAnswers();
    const mappedLatestAnswers = addIsDummyToAnswerArray(latestAnswers);
    const userAnswers = mergeAndSanitizeAnswers(
      mappedLatestAnswers,
      answersToValidate,
      conditions
    );

    //Get other party latest answers
    const otherPartyAnswers = getOtherPartyLatestAnswers();

    //Reference answers is always in relation to current user?
    const referenceAnswers = getReferenceAnswers(
      otherPartyAnswers,
      userAnswers
    );

    //Initialize panelData object
    const newPanelData: NegotiationPanelData = {
      subject: subjectType,
      subjectName: parseAnswers(referenceAnswers)[subjectFieldName],
      steps: [],
      referenceAnswers: referenceAnswers
    };

    for (let i = 0; i < steps.length; i++) {
      const step = steps[i];
      newPanelData.steps.push({
        id: step.id,
        counteredCount: 0,
        negotiatingCount: 0,
        terms: []
      });

      for (let j = 0; j < step.subSteps.length; j++) {
        const subStep = step.subSteps[j];
        for (let k = 0; k < subStep.questions.length; k++) {
          const question = subStep.questions[k];

          //Skip this question/term if it is hidden
          if (question.hidden) {
            continue;
          }

          const isNonNegotiable = nonNegotiable.indexOf(question.field.id) > -1;

          //Skip this question/term if condition is not met
          const condition = conditions.questions?.find(
            (c: any) => c[question.id] !== undefined
          );

          if (
            !isNonNegotiable &&
            condition &&
            !parseConditions(condition, parseAnswers(referenceAnswers))
          ) {
            continue;
          }

          const userAnswer = userAnswers.find(
            (answer) => answer.questionId === question.id
          );
          const otherPartyAnswer = otherPartyAnswers.find(
            (answer) => answer.questionId === question.id
          );

          /**
           * Commented this block out on April 24, 2023
           * I assume dthis was no longer necessary
           * and that it was a relic of the previous
           * version of the negotiation panel.
           * There should not be a neeed to skip a question if
           * neither party has responded, given the
           * conditional checks were done prior
           */
          //Skip this question/term if neither party has answered
          // if (
          //   !isNonNegotiable &&
          //   !userAnswer?.value &&
          //   !otherPartyAnswer?.value
          // ) {
          //   continue;
          // }

          const term: NegotiationTerm = {
            step: step.id,
            subStep: subStep.id,
            question: question.id,
            fieldId: question.field.id,
            status: null,
            label: question.label,
            conditionalLabels: question.conditionalLabels,
            tooltip: question.tooltip,
            prefix: question.field.prefix,
            postfix: question.field.postfix,
            field: question.field //Hack to get field for counter-offer form control
          };

          const isZipCode = term.label?.toLowerCase()?.includes('code'); //Hack to prevent commas from being added to Zip code          const isCountered = userAnswer?.status === 'new';
          const isCountered = userAnswer?.status === 'new';
          const isReceiverFirst =
            (userAnswer?.value === undefined &&
              otherPartyAnswer?.value === undefined) ||
            (userAnswer?.value === null && otherPartyAnswer?.value === null);
          /**
           * In some cases the receiver will be the first person to respond to a question
           * e.g. the initiator skipped the question based on conditional logic
           * In these cases when the receiver is about to answer the question
           * i.e. negotiate, both parties' answers will be undefined.
           * The null check is done also just to be safe.
           */
          const isNegotiating = userAnswer?.value !== otherPartyAnswer?.value;
          const isFinal = userAnswer?.value === otherPartyAnswer?.value;

          if (isNonNegotiable) {
            const initiatorValue = isUserInitiator
              ? userAnswer?.value
              : otherPartyAnswer?.value;
            const receiverValue = isUserInitiator
              ? otherPartyAnswer?.value
              : userAnswer?.value;

            /**
             * Gets the role for this non-negotiable question by checking the condition
             * or$ conditons are not checked because it wouldn't make sense for non-negotiable
             * The condition check would not work for complicated conditons e.g. nested and$
             * We need to bear this in mind when creating non-negotiables. Perhaps we should
             * explicitly state roles in the contract type definition
             */
            let role: string = get(condition, `${question.id}.role.eq$`);

            if (!role) {
              const arr: any[] | undefined = get(
                condition,
                `${question.id}.and$`
              );
              if (arr) {
                let arrRole = arr.find((a) => a.role !== undefined);

                if (!arrRole) {
                  role =
                    initiatorValue !== undefined
                      ? initiator?.role
                      : receiver?.role;
                } else {
                  role = get(arrRole, 'role.eq$');
                }
              } else {
                role =
                  initiatorValue !== undefined
                    ? initiator?.role
                    : receiver?.role;
                console.log(
                  condition,
                  get(condition, `${question.id}.role.eq$`)
                );
              }
            }

            let value =
              role === initiator.role ? initiatorValue : receiverValue;

            const newTerm: any = {
              ...term,
              role: role != null ? role.toUpperCase() : '',
              value: addCommasToNumber(value, isZipCode)
            };

            if (
              !isContractSummary &&
              isUserResponding &&
              ((isUserInitiator && role === initiator.role) ||
                (!isUserInitiator && role === receiver.role))
            ) {
              if (
                condition &&
                !parseConditions(condition, parseAnswers(referenceAnswers))
              ) {
                continue;
              }

              if (isCountered) {
                newPanelData.steps[i].counteredCount++;
                newPanelData.steps[i].negotiatingCount++;
                newTerm.status = 'non-negotiable-answered';
                newTerm.userAnswer = addCommasToNumber(
                  userAnswer.value,
                  isZipCode
                );
              } else if (
                (isUserInitiator &&
                  role === initiator.role &&
                  initiatorValue === undefined) ||
                (!isUserInitiator &&
                  role === receiver.role &&
                  receiverValue === undefined)
              ) {
                newPanelData.steps[i].negotiatingCount++;
                newTerm.status = 'non-negotiable-input';
              } else {
                newTerm.status = 'non-negotiable';
              }
            } else {
              if (
                condition &&
                !parseConditions(condition, parseAnswers(referenceAnswers))
              ) {
                continue;
              }

              newTerm.status = 'non-negotiable';
            }

            newPanelData.steps[i].terms.push(newTerm);
            continue;
          }

          if (isCountered) {
            newPanelData.steps[i].counteredCount++;
            newPanelData.steps[i].negotiatingCount++;
            newPanelData.steps[i].terms.push({
              ...term,
              status: 'countered',
              userAnswer: addCommasToNumber(userAnswer.value, isZipCode),
              otherPartyAnswer: addCommasToNumber(
                otherPartyAnswer?.value,
                isZipCode
              ),
              history: getNegotiationTermHistory(question.id)
            });
            continue;
          }

          if (isReceiverFirst) {
            newPanelData.steps[i].negotiatingCount++;
            newPanelData.steps[i].terms.push({
              ...term,
              status: 'receiver-first',
              userAnswer: null,
              otherPartyAnswer: null
            });
            continue;
          }

          if (isNegotiating) {
            newPanelData.steps[i].negotiatingCount++;
            newPanelData.steps[i].terms.push({
              ...term,
              status: 'negotiating',
              userAnswer: addCommasToNumber(userAnswer?.value, isZipCode),
              otherPartyAnswer: addCommasToNumber(
                otherPartyAnswer?.value,
                isZipCode
              ),
              userAnswerValue: userAnswer?.value,
              otherPartyAnswerValue: otherPartyAnswer?.value,
              history: getNegotiationTermHistory(question.id)
            });
            continue;
          }

          if (isFinal) {
            newPanelData.steps[i].terms.push({
              ...term,
              status: 'final',
              value: addCommasToNumber(userAnswer?.value, isZipCode),
              history: getNegotiationTermHistory(question.id)
            });
            continue;
          }
        }
      }
    }

    setPanelData(newPanelData);
  }, [
    conditions,
    initiator.role,
    isFirstCounter,
    isUserInitiator,
    isUserResponding,
    negotiation,
    newAnswers,
    nonNegotiable,
    receiver?.role,
    steps,
    subjectFieldName,
    subjectType,
    isContractSummary
  ]);

  useEffect(() => {
    setAccordionState((previous) => {
      if (previous.index === -1) {
        return {
          ...previous,
          steps: [...panelData.steps]
        };
      } else {
        return {
          ...previous,
          steps: [...panelData.steps]
        };
      }
    });
    if (
      Object.keys(viewStatuses).length === 0 &&
      panelData?.steps?.length > 0 &&
      !isContractSummary
    ) {
      const temp = cloneDeep(viewStatuses);
      for (const step of panelData.steps) {
        temp[step.id] = true;
      }
      setViewStatuses(temp);
    }
  }, [isContractSummary, panelData, viewStatuses]);

  const onAcceptOffer = async (term: NegotiationTerm) => {
    setValue(term.fieldId, term.otherPartyAnswerValue);
    const result = await trigger(term.fieldId);
    if (!result) {
      return;
    }

    setNewAnswers((previous) => [
      ...previous,
      {
        stepId: term.step,
        subStepId: term.subStep,
        questionId: term.question,
        fieldId: term.fieldId,
        value: term.otherPartyAnswerValue,
        status: 'new'
      }
    ]);
  };

  const onCounterOffer = async (term: NegotiationTerm) => {
    const result = await trigger(term.fieldId);
    if (!result) {
      return;
    }

    const value = getValues()[term.fieldId];

    setNewAnswers((previous) => [
      ...previous,
      {
        stepId: term.step,
        subStepId: term.subStep,
        questionId: term.question,
        fieldId: term.fieldId,
        value,
        status: 'new'
      }
    ]);
  };

  const onEditCounterOffer = async (term: any) => {
    const answers = [...newAnswers].filter(
      (answer: Answer) => answer.fieldId !== term.fieldId
    );
    setNewAnswers(answers);
  };

  const onToggleViewStatus = (id: string) => {
    const status = viewStatuses[id];
    if (status === undefined) {
      setViewStatuses((statuses) => ({
        ...statuses,
        [id]: true
      }));
    } else {
      setViewStatuses((statuses) => ({
        ...statuses,
        [id]: !status
      }));
    }
  };

  const onToggleHistoryStatus = (id: string) => {
    const status = historyStatuses[id];
    if (status === undefined) {
      setHistoryStatuses((statuses) => ({
        ...statuses,
        [id]: true
      }));
    } else {
      setHistoryStatuses((statuses) => ({
        ...statuses,
        [id]: !status
      }));
    }
  };

  const onToggleAccordion = (index: number) => {
    if (index === -1) {
      setAccordionState({
        index: -1,
        steps: [...panelData.steps]
      });
    } else {
      // const filteredSteps = panelData.steps.filter(
      //   (step, i) => i === index.valueOf()
      // );
      setAccordionState({
        index,
        steps: [...panelData.steps]
      });
    }
  };

  const handleSubmit = async () => {
    onSubmitAnswers(newAnswers);
  };

  const onShowTooltip = (tooltip: any) => {
    setTooltipState({
      ...tooltip,
      isOpen: true
    });
  };

  const onCloseTooltip = () => {
    setTooltipState({
      header: '',
      content: '',
      isOpen: false
    });
  };

  return (
    <Flex
      flexDirection="column"
      as="form"
      noValidate
      padding={{ base: '8px 8px 8px 8px', xl: '32px 32px 32px 32px' }}
      borderRadius={{ base: '0', xl: '8px' }}
      sx={{ backdropFilter: 'blur(120px)' }}
      filter="drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25))"
      bg={bg}
      // bg={'red'}
      mx={{ base: '0px', lg: '100px' }}
      h={{ base: '100%', lg: '80%' }}
      overflowY="auto"
      flex="1"
      {...props}
    >
      <Flex
        justifyContent="space-between"
        flexDir={{ base: 'column', lg: 'row' }}
      >
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Flex flexDirection="column">
            <Heading
              as="h1"
              fontFamily="Poppins"
              fontSize="56px"
              lineHeight="68.4px"
              fontWeight="400"
              mb="6px"
              color={headerColor}
            >
              {panelData.subject}: '{panelData.subjectName}'
            </Heading>
            <Text
              fontFamily="Open Sans"
              fontSize="12px"
              lineHeight="16px"
              fontWeight="400"
              mb="12px"
              color={headerColor}
            >
              Contract started:
              <b>{` "${moment(createdAt).format('MMM DD, YYYY')}"`}</b>
            </Text>
          </Flex>
          <Flex alignItems="center" justifyItems={'end'} gap="10px" mb="16px">
            <Text
              fontFamily="Open Sans"
              fontSize="40px"
              lineHeight="16px"
              fontWeight="700"
              color={headerColor}
            >
              {termsRemaining < 10 ? `0${termsRemaining}` : termsRemaining}
            </Text>
            <Text fontFamily="Open Sans" color={headerColor}>
              Terms
              <br />
              remaining
            </Text>
            <Text
              fontFamily="Open Sans"
              fontSize="40px"
              lineHeight="16px"
              fontWeight="700"
              color={headerColor}
            >
              {termsNegotiated < 10 ? `0${termsNegotiated}` : termsNegotiated}
            </Text>
            <Text fontFamily="Open Sans" color={headerColor}>
              Terms
              <br />
              negotiated
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        flex="1"
        flexDirection="column"
        justifyContent="space-between"
        gap="32px"
      >
        <Accordion
          flex="1"
          allowToggle={true}
          variant="panel"
          onChange={onToggleAccordion}
          index={accordionState.index}
        >
          {steps.map((step: any, index: number) => {
            const negotiationStep = accordionState.steps.find(
              (n) => n.id === step.id
            );

            if (!negotiationStep) {
              return <React.Fragment key={step.id}></React.Fragment>;
            }

            return (
              <AccordionItem key={step.id}>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton bg={'black'}>
                      <Flex justifyContent="space-between" w="100%">
                        <Text
                          fontSize="24px"
                          fontWeight="600"
                          lineHeight="32.68px"
                          color={highlightColor}
                        >
                          {step.title}
                        </Text>
                        {negotiationStep.negotiatingCount === 0 ? (
                          <Flex gap="8px" alignItems="center">
                            <Check color={successColor} h="16px" w="16px" />
                            <Text
                              color={successColor}
                              fontSize="12px"
                              lineHeight="16px"
                              fontWeight="600"
                              mr="8px"
                            >
                              All approved
                            </Text>
                          </Flex>
                        ) : (
                          <Flex gap="8px" alignItems="center">
                            <Notification
                              color={notificationColor}
                              h="16px"
                              w="16px"
                            />
                            <Text
                              color={notificationColor}
                              fontSize="12px"
                              lineHeight="16px"
                              fontWeight="600"
                              mr="8px"
                            >
                              {negotiationStep.negotiatingCount -
                                negotiationStep.counteredCount}{' '}
                              terms remaining
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                      <Dropdown
                        color={highlightColor}
                        h="4px"
                        w="12px"
                        sx={
                          isExpanded
                            ? {
                                transform: 'rotate(180deg)'
                              }
                            : {}
                        }
                      />
                    </AccordionButton>

                    <AccordionPanel>
                      <Divider
                        orientation="horizontal"
                        border="1px solid"
                        color={panelDividerColor}
                      />
                      {!isContractSummary && (
                        <Flex py="8px" alignItems="center" gap="6px">
                          <Text
                            fontSize="12px"
                            lineHeight="14.4px"
                            fontWeight={
                              !viewStatuses[negotiationStep.id] ? '700' : '400'
                            }
                            color={
                              !viewStatuses[negotiationStep.id]
                                ? highlightColor
                                : textColor
                            }
                          >
                            All
                          </Text>
                          <Switch
                            onChange={() => onToggleViewStatus(step.id)}
                            defaultChecked={viewStatuses[step.id]}
                          />
                          <Text
                            fontSize="12px"
                            lineHeight="14.4px"
                            fontWeight={
                              viewStatuses[negotiationStep.id] ? '700' : '400'
                            }
                            color={
                              viewStatuses[negotiationStep.id]
                                ? highlightColor
                                : textColor
                            }
                          >
                            Negotiating
                          </Text>
                        </Flex>
                      )}
                      <Box h={{ base: '90%' }} overflowY="auto">
                        {negotiationStep.terms
                          .filter((term) => {
                            if (!viewStatuses[negotiationStep.id]) {
                              return true;
                            }

                            return (
                              term.status === 'negotiating' ||
                              term.status === 'countered' ||
                              term.status === 'receiver-first' ||
                              term.status === 'non-negotiable-input' ||
                              term.status === 'non-negotiable-answered'
                            );
                          })
                          .map((term) => (
                            <Flex
                              key={term.fieldId}
                              bg={termBackground}
                              padding="24px 24px 16px 24px"
                              mb="12px"
                              flexDirection="column"
                              borderRadius="5px"
                            >
                              <Flex flexDir="column">
                                {term.role && (
                                  <Flex
                                    background="brand.secondary2"
                                    h="25px"
                                    maxWidth="120px"
                                    borderRadius="5px"
                                    alignItems="center"
                                    justifyContent="center"
                                    mb="15px"
                                  >
                                    <Text
                                      fontSize="10px"
                                      fontWeight="700"
                                      lineHeight="12px"
                                      color={highlightColor}
                                    >
                                      {term.role.toUpperCase()}
                                    </Text>
                                  </Flex>
                                )}
                                <Flex
                                  gap={{ base: '2px', lg: '8px' }}
                                  alignItems={{
                                    base: 'flex-start',
                                    lg: 'center'
                                  }}
                                  cursor="pointer"
                                  mb="15px"
                                  flexDir={{ base: 'column', lg: 'row' }}
                                >
                                  <ChartaText
                                    answers={panelData.referenceAnswers}
                                  >
                                    {parseQuestionLabel(
                                      term,
                                      parseAnswers(panelData.referenceAnswers)
                                    )}
                                  </ChartaText>
                                  <Flex
                                    h="20.5px"
                                    width="20.5px"
                                    borderRadius="100%"
                                    bg="linear-gradient(#FDE033, #EE4373)"
                                    alignItems="center"
                                    justifyContent="center"
                                    hidden={!term.tooltip}
                                    mt={{ base: '15px', lg: '0' }}
                                    onClick={() => onShowTooltip(term.tooltip)}
                                  >
                                    <Info color="#1B1C31" h="16px" w="18.5px" />
                                  </Flex>
                                </Flex>
                              </Flex>

                              {term.status === 'final' ? (
                                <Flex gap="8px" alignItems="center">
                                  <Text
                                    color={successColor}
                                    fontWeight="700"
                                    fontSize="16px"
                                    lineHeight="20px"
                                  >
                                    Final term: {term.value}
                                  </Text>
                                  <Check
                                    color={successColor}
                                    h="16px"
                                    w="16px"
                                  />
                                </Flex>
                              ) : term.status === 'non-negotiable' ? (
                                <Flex gap="8px" alignItems="center">
                                  <Text
                                    color={successColor}
                                    fontWeight="700"
                                    fontSize="16px"
                                    lineHeight="20px"
                                  >
                                    {term.value}
                                  </Text>
                                  <Check
                                    color={successColor}
                                    h="16px"
                                    w="16px"
                                  />
                                </Flex>
                              ) : term.status === 'countered' ? (
                                <Flex justifyContent="space-between">
                                  <Stack>
                                    <Flex gap="8px" alignItems="center">
                                      <Text
                                        color={offerColor}
                                        fontSize="16px"
                                        fontWeight="700"
                                        lineHeight="20px"
                                      >
                                        Your new offer: {term.prefix}
                                        {term.userAnswer}
                                        {term.postfix}
                                      </Text>
                                      <Notification
                                        color={notificationColor}
                                        h="16px"
                                        w="16px"
                                      />
                                    </Flex>
                                    <Flex alignItems="center">
                                      <Text
                                        color={textColor}
                                        fontSize="12px"
                                        fontWeight="400"
                                        lineHeight="12px"
                                      >
                                        Their last offer:
                                      </Text>
                                      &nbsp;
                                      <Text
                                        color={textColor}
                                        fontSize="12px"
                                        fontWeight="700"
                                        lineHeight="12px"
                                      >
                                        {term.prefix}
                                        {term.otherPartyAnswer || '-'}
                                        {term.postfix}
                                      </Text>
                                    </Flex>
                                  </Stack>
                                  <Button
                                    onClick={() => onEditCounterOffer(term)}
                                    hidden={!isUserResponding}
                                    background={
                                      negotiationPanelTheme.negotiationButtonColor
                                    }
                                  >
                                    Edit
                                  </Button>
                                </Flex>
                              ) : term.status === 'negotiating' ? (
                                <Flex
                                  justifyContent="space-between"
                                  flexDir={{ base: 'column', lg: 'row' }}
                                >
                                  <Stack>
                                    <Flex gap="8px">
                                      <Text
                                        color={offerColor}
                                        fontSize="16px"
                                        fontWeight="700"
                                        lineHeight="20px"
                                      >
                                        Their offer: {term.prefix}
                                        {term.otherPartyAnswer}
                                        {term.postfix}
                                      </Text>
                                      <Notification
                                        color={notificationColor}
                                        h="16px"
                                        w="16px"
                                      />
                                    </Flex>
                                    <Flex>
                                      <Text
                                        color={textColor}
                                        fontSize="12px"
                                        fontWeight="400"
                                        lineHeight="12px"
                                      >
                                        Your last offer:
                                      </Text>
                                      &nbsp;
                                      <Text
                                        color={textColor}
                                        fontSize="12px"
                                        fontWeight="700"
                                        lineHeight="12px"
                                      >
                                        {term.prefix}
                                        {term.userAnswer}
                                        {term.postfix}
                                      </Text>
                                    </Flex>
                                  </Stack>
                                  {isUserResponding && (
                                    <Flex
                                      gap="20px"
                                      alignItems="start"
                                      justifyContent="end"
                                      flexDir={{
                                        base: 'column',
                                        lg: 'row'
                                      }}
                                    >
                                      <FormControl
                                        key={term.fieldId}
                                        isInvalid={!!errors[term.fieldId]}
                                        w={{ base: '100%', lg: '40%' }}
                                      >
                                        <ChartaField
                                          field={term.field}
                                          color={textColor}
                                          register={register}
                                          getValues={getValues}
                                          setValue={setValue}
                                          validations={validations}
                                          control={control}
                                          negotiation={true}
                                        />
                                        <FormErrorMessage>
                                          {errors[term.fieldId] &&
                                            (errors[term.fieldId]
                                              ?.message as string)}
                                        </FormErrorMessage>
                                      </FormControl>
                                      <Flex
                                        gap="8px"
                                        justifyContent={{
                                          base: 'center',
                                          lg: 'flex-start'
                                        }}
                                        flexDir={{ base: 'column', lg: 'row' }}
                                      >
                                        <Button
                                          onClick={() => onCounterOffer(term)}
                                          background={
                                            negotiationPanelTheme.counterOfferBackgroundColor
                                          }
                                        >
                                          Make Counteroffer
                                        </Button>
                                        <Button
                                          variant="transparent"
                                          disabled={
                                            term?.otherPartyAnswer === 'pending'
                                          }
                                          onClick={() => onAcceptOffer(term)}
                                          color={
                                            negotiationPanelTheme.negotiationButtonColor
                                          }
                                          borderColor={'white'}
                                        >
                                          Accept Offer
                                        </Button>
                                      </Flex>
                                    </Flex>
                                  )}
                                </Flex>
                              ) : term.status === 'non-negotiable-input' ||
                                term.status === 'receiver-first' ? (
                                <Flex justifyContent="space-between">
                                  <Stack>
                                    <Flex gap="8px" alignItems="center">
                                      <Text
                                        color={offerColor}
                                        fontSize="16px"
                                        fontWeight="700"
                                        lineHeight="20px"
                                      >
                                        Your answer: {term.prefix}
                                        {term.userAnswer}
                                        {term.postfix}
                                      </Text>
                                      <Notification
                                        color={notificationColor}
                                        h="16px"
                                        w="16px"
                                      />
                                    </Flex>
                                  </Stack>
                                  <Flex
                                    justifyContent={{
                                      base: 'flex-start',
                                      lg: 'flex-end'
                                    }}
                                    gap="16px"
                                  >
                                    <FormControl
                                      key={term.fieldId}
                                      isInvalid={!!errors[term.fieldId]}
                                      w={{ base: '100%', lg: '60%' }}
                                    >
                                      <ChartaField
                                        field={term.field}
                                        register={register}
                                        getValues={getValues}
                                        setValue={setValue}
                                        validations={validations}
                                        control={control}
                                        negotiation={true}
                                        color={textColor}
                                      />
                                      <FormErrorMessage>
                                        {errors[term.fieldId] &&
                                          (errors[term.fieldId]
                                            ?.message as string)}
                                      </FormErrorMessage>
                                    </FormControl>
                                    <Button
                                      onClick={() => onCounterOffer(term)}
                                      background={
                                        negotiationPanelTheme.negotiationButtonColor
                                      }
                                    >
                                      Submit
                                    </Button>
                                  </Flex>
                                </Flex>
                              ) : term.status === 'non-negotiable-answered' ? (
                                <Flex justifyContent="space-between">
                                  <Stack>
                                    <Flex gap="8px" alignItems="center">
                                      <Text
                                        color={offerColor}
                                        fontSize="16px"
                                        fontWeight="700"
                                        lineHeight="20px"
                                      >
                                        Your answer: {term.prefix}
                                        {term.userAnswer}
                                        {term.postfix}
                                      </Text>
                                      <Notification
                                        color={notificationColor}
                                        h="16px"
                                        w="16px"
                                      />
                                    </Flex>
                                  </Stack>
                                  <Button
                                    onClick={() => onEditCounterOffer(term)}
                                    hidden={!isUserResponding}
                                    background={
                                      negotiationPanelTheme.negotiationButtonColor
                                    }
                                  >
                                    Edit
                                  </Button>
                                </Flex>
                              ) : (
                                <>Invalid Status: {term.status}</>
                              )}
                              <Flex
                                gap="9px"
                                alignItems="center"
                                mt="16px"
                                mb="7px"
                                onClick={() =>
                                  onToggleHistoryStatus(term.fieldId)
                                }
                                _hover={{ cursor: 'pointer' }}
                                display={
                                  [
                                    'receiver-first',
                                    'non-negotiable',
                                    'non-negotiable-input',
                                    'non-negotiable-answered'
                                  ].includes(term.status!)
                                    ? 'none'
                                    : 'flex'
                                }
                              >
                                <Text
                                  color={highlightColor}
                                  fontSize="12px"
                                  fontWeight="600"
                                  lineHeight="16.34px"
                                >
                                  Negotiation History
                                </Text>
                                <Dropdown
                                  color={highlightColor}
                                  h="4px"
                                  w="8px"
                                  sx={
                                    historyStatuses[term.fieldId]
                                      ? {
                                          transform: 'rotate(180deg)'
                                        }
                                      : {}
                                  }
                                />
                              </Flex>
                              {historyStatuses[term.fieldId] && (
                                <>
                                  {term.history?.map(
                                    (answer: any, index: number) => (
                                      <Flex
                                        key={index}
                                        alignItems="center"
                                        justifyContent="flex-start"
                                        borderBottom="solid 0.5px"
                                        borderColor={tableBorderColor}
                                        py="8px"
                                        borderTop={
                                          index === 0 ? 'solid 1px' : ''
                                        }
                                      >
                                        <Flex
                                          gap="12px"
                                          alignItems="center"
                                          flex="1 1 0"
                                        >
                                          <Avatar
                                            src={answer.imageUrl}
                                            size="sm"
                                            display={{
                                              base: 'none',
                                              lg: 'initial'
                                            }}
                                          />
                                          <Flex flexDirection="column">
                                            <Text
                                              fontSize="12px"
                                              fontWeight="400"
                                              lineHeight="16px"
                                              color={tableTextColor}
                                            >
                                              {answer.name}
                                            </Text>
                                            <Text
                                              fontSize="10px"
                                              fontWeight="700"
                                              lineHeight="12px"
                                              color={tableTextColor}
                                            >
                                              {answer.role}
                                            </Text>
                                          </Flex>
                                        </Flex>
                                        <Text
                                          flex="1 1 0"
                                          fontSize="12px"
                                          fontWeight="700"
                                          lineHeight="16px"
                                          color={tableTextColor}
                                          align={{
                                            base: 'center',
                                            lg: 'left'
                                          }}
                                        >
                                          {addCommasToNumber(
                                            answer.value,
                                            term.label === 'Zip Code'
                                          )}
                                        </Text>
                                        <Text
                                          flex="1 1 0"
                                          fontSize="12px"
                                          fontWeight="400"
                                          lineHeight="16px"
                                          color={tableTextColor}
                                          align={{
                                            base: 'right',
                                            lg: 'left'
                                          }}
                                        >
                                          {answer.date}
                                        </Text>
                                      </Flex>
                                    )
                                  )}
                                </>
                              )}
                            </Flex>
                          ))}
                      </Box>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            );
          })}
        </Accordion>
        <Flex justifyContent="end">
        <Button
            disabled={accordionState?.index >= 2}
            marginRight={50}
            onClick={() => {navigate('/dashboard');}}
            color={negotiationPanelTheme.buttonTextColor}
          >
            Back to Dashboard
          </Button>
          <Button
            disabled={accordionState?.index >= 2}
            marginRight={50}
            onClick={() => {
              setAccordionState((previous) => {
                return {
                  ...previous,
                  index: previous?.index + 1
                };
              });
            }}
            color={negotiationPanelTheme.buttonTextColor}
          >
            Next
          </Button>
          {!negotiation.paymentDone && ( 
            <Button
              type="button"
              onClick={() => onOpenPayment()}
              data-heap-id={`${contractTypeName}.pay-negotiation`}
              color={negotiationPanelTheme.buttonTextColor}
            >
              Pay Contract
            </Button>
          )}
          {isContractSummary && negotiation.paymentDone ? (
            <Button
              type="button"
              onClick={() => onViewContract()}
              data-heap-id={`${contractTypeName}.view-contract`}
              color={negotiationPanelTheme.buttonTextColor}
            >
              View Contract
            </Button>
          ) : (
            isUserResponding && negotiation.paymentDone && (
              <Button
                type="button"
                onClick={() => handleSubmit()}
                disabled={termsRemaining > 0}
                data-heap-id={`${contractTypeName}.submit-negotiation`}
                color={negotiationPanelTheme.buttonTextColor}
              >
                Submit
              </Button>
            )
          )}
        </Flex>
      </Flex>
      <Drawer
        onClose={onCloseTooltip}
        isOpen={tooltipState.isOpen}
        placement="right"
      >
        <DrawerOverlay />
        <DrawerContent p="16px" bg="rgba(27, 28, 49, 1)">
          <Heading fontSize="32px">{tooltipState.header}</Heading>
          <ChartaProse mt="16px">{tooltipState.content}</ChartaProse>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};
