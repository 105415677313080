import api from '../axios/axios';

export const getContractType = (id: string) => {
  return api.get(`/contract-types/${id}`);
};

export const getContractTypes = () => {
  return api.get('/contract-types');
};

export const getNegotiatingContractType = (id: string) => {
  return api.get(`/contract-types/negotiation/${id}`);
};

export const createContract = (negotiationId: string) => {
  return api.post(`/contract`, { negotiationId });
};

export const getContractByNegotiationId = (id: string) => {
  return api.get(`/contract/negotiationId/${id}`);
};

export const getContractById = (id: string) => {
  return api.get(`/contract/${id}`);
};

export const signContract = (data: {
  contractId: string;
  lightSignature: string;
  darkSignature: string;
  name: string;
}) => {
  return api.post('/contract/sign', data);
};

export const getContractsByStatus = (
  limit: number,
  offset: number,
  status: string
) => {
  return api.get(`/contract?limit=${limit}&offset=${offset}&status=${status}`);
};

export const getContractsByStatusNoLimit = (status: string) => {
  return api.get(`/contract?status=${status}`);
};

export const getContractsCountByStatus = (status: string) => {
  return api.get(`/contract/count/${status}`);
};

export const getContractPDF = (negotiationId: string) => {
  return api.get(`/contract/getPdfbyNegotiationId/${negotiationId}`);
};

export const getContractDocx = (negotiationId: string) => {
  return api.get(`/contract/getDocxbyNegotiationId/${negotiationId}`);
};
